"use strict";



const local ='http://localhost:3477'
const cpanel ='https://sushilitos.com.mx' // cambiar por la url del sitio web.

exports.url  =  cpanel + '/api/Ncl_q3O/'; 


