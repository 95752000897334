<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    scroll(event) {
      let number = event.target.documentElement.scrollTop;
      let offset = number * 1;
      localStorage.setItem("scroll", offset);
      this.scrollP = localStorage.getItem("scroll");
    },
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
};

</script>

<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@100;200;300;400;500;600;700;800;900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
  
@font-face {
  font-family: "SolfeggioBlack" /*900*/;
  src: url("./assets/fonts/solfeggioBlack.OTF");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWorldReg" /*400*/;
  src: url("./assets/fonts/HelveticaWorldRegular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "HelveticaWordlBold" /*700*/;
  src: url("./assets/fonts/HelveticaWorldBold.ttf");
  font-display: swap;
}
  
  @font-face {
    font-family: "SolfeggioBold"; /*700 */
    src: url("./assets/fonts/solfeggioBold.OTF");
    font-display: swap;
  }
  
  @font-face {
    font-family: "SolfeggioExtraBold"; /*800 */
    src: url("./assets/fonts/solfeggioExtraBold.OTF");
    font-display: swap;
  }
  
  @font-face {
    font-family: "SolfeggioLight"; /*300 */
    src: url("./assets/fonts/solfeggioLight.OTF");
    font-display: swap;
  }
  
  @font-face {
    font-family: "SolfeggioMedium"; /*500 */
    src: url("./assets/fonts/solfeggioMedium.OTF");
    font-display: swap;
  }
  
  @font-face {
    font-family: "SolfeggioRegular"; /*400*/
    src: url("./assets/fonts/solfeggioRegular.OTF");
    font-display: swap;
  }


  html{
    scroll-behavior: smooth;
  }

  :root{
    /** No eliminar los 7 colores */
    --color-1: #E21F1D;
    --color-2:  #242424;
    --color-3: #A7A7A7;
    --color-4: #F5F5F5;
    --color-5:  #FFFFFF;
    --color-6:  #D9D9D9;
    --color-7:  #FFFFFF;
    
    --Sans: 'Source Sans Pro', sans-serif;
    --Open_Sans: 'Open Sans', sans-serif;
    --Sora: 'Sora', sans-serif;
    --Mukta: 'Mukta', sans-serif;
    --dmsans:'DM Sans', sans-serif;
    --montserrat:'Montserrat', sans-serif;
    --inter: 'Inter', sans-serif;
    --roboto: 'Roboto', sans-serif;
    --robotos: 'Roboto Serif', serif;
  }

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
  --dmsans: "DM Sans", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --inter: "Inter", sans-serif;
  --Roboto: 'Roboto Serif', serif;

  --fs-idseccionpc: 0.625vw;

  /* Tipografias */
  --font-titulo1: "Outfit";
  --font-titulo2: "Outfit";
  --font-parrafo: "Outfit";
  --font-parrafo2: "Outfit";
}

a {
  text-decoration: none;
  color: inherit;
}
.algc {
  align-items: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
  overflow: hidden;
}

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}
.box_rh {
  display: flex;
  justify-content: right;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crs_ptr {
  cursor: pointer;
}

/* Carrusel */
.VueCarousel-inner {
  height: 24.2vw !important;
  width: 76.19791666666667VW !important;
}

.VueCarousel-inner > div:nth-of-type(odd) {
  margin-top: 2.1875VW
}

.VueCarousel-pagination {
  display: none;
}

.VueCarousel-navigation > button {
  opacity: 1 !important;
  position: absolute;
}

.VueCarousel-navigation button img {
  width: 0.703125VW;
  height: 2.604166666666667VW;
}

.VueCarousel-navigation > button:nth-of-type(1) {
  transform: rotate(180deg);
  left: 76vw;
  top: 8.9791666666666665VW;
}

.VueCarousel-navigation > button:nth-of-type(2) {
  top: 8.2vw;
}

.VueCarousel-navigation-prev {
  margin-top: 1.9791666666666665VW;
}
.cart_msg {
    margin: 7vw 0;
    text-align: center;
    font-size: 2.8vw;
    font-family: var(--robotos);
    font-weight: 500;
}
.cart_error{
  color: #a31f25;
}
.cart_success{
    color: #a16d34;
}


/**ESTILOS ADMINISTRADOR */
.tabla_perzonalidada {
  width: 60%;
  margin-top: 1vw;
}
.header_tp {
  display: flex;
  height: 2vw;
  align-items: center;
}
 .header_tp p{
  margin: 0vw;
  color: white;
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  text-align: center;
  background:var(--color-1);
}
.header_tp p:first-child {
  width: 80%;
}
.header_tp p:last-child {
  width: 20%;
}
.body_tp {
  overflow: auto;
  margin-top: 0.5vw;
  margin-bottom: 2vw;
}

.content-tbl-items {
  margin: 0.4vw 0;
}
.bodytbl-items {
  border: 0.052083vw solid var(--color-2);
  padding: 0.5vw;
  height: 5vw;
  font-size: 0.8vw;
  overflow: auto;
  background: var(--color-2);
  color: var(--color-5);
}
.two-options .to-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.75vw;
  height: 1.75vw;
  background: rgb(165, 165, 165);
  border-radius: 2vw;
  font-size: 0.7291666666666666vw;
  color: white;
  font-weight: 600;
  margin-right: .20vw;
  cursor: pointer;
  transition: .5;
  -webkit-transition: .5;
  -moz-transition: .5;
  -ms-transition: .5;
  -o-transition: .5;
}
.two-options .to-btn:last-child{
  margin-right: 0;
}
.two-options .to-btn.active{
  background: var(--color-2);
}
.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-edit-adm {
  width: 46vw;
  height: 3vw;
  margin: auto;
  background-color: var(--color-1);
  border-radius: 1vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.header-edit-adm a {
  font-size: .7vw;
  color: white;
  font-family: var(--font-titulo2);
  background: transparent;
  border-bottom: .1vw solid transparent;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}
.header-edit-adm a:hover{
  border-bottom: .1vw solid white;
}
p.subtitle {
  color: var(--color-2);
  margin: 0vw;
  font-size: 1.3020833333333335vw;
  margin-top: 2vw;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1vw;
}
p.hrd_tbli{
  background: var(--color-1);
  color: var(--color-5);
  text-align: center;
  padding: .5vw 0vw ;
  margin: 0vw;
    font-size: .8vw;

} 
.sinitems {
  background: #d6d6d6;
  width: 100%;
}
.sinitems p {
  text-align: center;
  color: #2e2e2e;
  margin: 0vw;
  height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  text-transform: uppercase;
}
.in1.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw !important;
  border-bottom: transparent !important;
}
textarea#descr,
textarea#wlink,
textarea#address {
  max-width: 37vw;
  min-width: 37vw;
  min-height: 5vw;
}
.header_tp {
  display: flex;
  height: 2vw;
  align-items: center;
}
.header_tp p {
  margin: 0vw;
  color: white;
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  text-align: center;
  background: var(--color-2);
}
.header_tp p:first-child {
  width: 50%;
}
.header_tp p:last-child {
  width: 20%;
}
.fila p {
  color: rgb(51, 51, 51);
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid white;
  justify-content: center;

  background-color: #dddddd;
  margin: 0vw;
}
.fila p:first-child {
  text-align: left;
  width: 48%;
  padding-left: 2%;
  justify-content: left;
}
.fila p:last-child {
  text-align: center;
  width: 20%;
  cursor: pointer;
}
.fila p:last-child:hover {
  font-weight: 800;
}
.fila {
  display: flex;
  height: 2vw;
  align-items: center;
  margin-bottom: 0.3vw;
} 
.reg_gu_cont {
  justify-content: center;
  margin-top: 2vw;
  margin-bottom: 5vw;
}
/*FIN ESTILOS ADMINISTRADOR */
@media (min-width: 1020px) {
  .cart_msg {
    margin: 3vw 0;
    text-align: left;
    font-size: 0.98vw; 
}
}
</style>
